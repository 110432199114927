<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검년도 -->
          <c-datepicker
            name="year"
            type="year"
            default="today"
            label="LBL0003648"
            v-model="searchParam.year"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 설비 -->
          <c-equip
            label="LBLEQUIP"
            type="search"
            name="equipmentCd"
            v-model="searchParam.equipmentCd">
          </c-equip>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 결과 -->
          <c-select
            type="search"
            codeGroupCd="MIM_CHECK_RESULT_CD"
            itemText="codeName"
            itemValue="code"
            name="resultCd"
            label="LBL0003785"
            v-model="searchParam.resultCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 실행기간 -->
          <c-datepicker
            :range="true"
            name="period"
            label="LBL0003786"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 현황분류 -->
          <c-select
            :editable="editable"
            :comboItems="typeItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="equipmentType"
            label="LBL0003787"
            v-model="searchParam.equipmentType"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 관련법규 -->
          <c-multi-select
            :editable="editable"
            codeGroupCd="REGULATION_BILL_API_CD"
            itemText="codeName"
            itemValue="code"
            label="관련법규"
            name="relatedLawList"
            maxValues="3"
            v-model="searchParam.relatedLawList">
          </c-multi-select>
        </div>
      </template>
    </c-search-box>
    <!-- 설비점검/정비완료 현황 목록 -->
    <c-table
      ref="table"
      title="LBL0003788"
      tableId="equipmentCd"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'resultName'">
          <div v-if="props.row['resultName'] === '부적합'" class="description-td">
            {{ props.row['resultName'] }}
          </div>
          <div v-else>
            {{ props.row['resultName'] }}
          </div>
        </template>
        <template v-if="col.name === 'statusTypeCd'">
          <q-chip
            :color="setTagColor(props.row.statusTypeCd)"
            outline square
            :clickable="true"
            text-color="white"
            class="full-size-chip"
            @click="linkClick(props.row, { name: 'statusTypeCd' })"
          >
            {{setTagName(props.row.statusTypeCd)}}
          </q-chip>
        </template>
      </template>
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <!-- 검색 -->
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "equipment-inspection",
  data() {
    return {
      grid: {
        merge: [
          { index: 1, colName: "equipmentCdName"},
        ],
        columns: [
          {
            name: "plantName",
            field: "plantName",
            // 사업장
            label: "LBLPLANT",
            style: 'width:80px',
            align: "center",
            sortable: false,
          },
          {
            name: "equipmentCdName",
            field: "equipmentCdName",
            // 설비명(관리번호)
            label: "LBL0003662",
            style: 'width:150px',
            align: "center",
            sortable: false,
            type: "link",
          },
          {
            name: "statusTypeCd",
            field: "statusTypeCd",
            // 현황분류
            label: "LBL0003787",
            style: 'width:50px',
            type: 'custom',
            align: "center",
            sortable: false,
          },
          {
            name: "deptName",
            field: "deptName",
            // 점검부서/정비입회자
            label: "LBL0003789",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: "typeName",
            field: "typeName",
            // 점검/정비 유형
            label: "LBL0003790",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: "cycleName",
            field: "cycleName",
            // 주기
            label: "LBL0003658",
            style: 'width:50px',
            align: "center",
            sortable: false,
          },
          {
            // 점검/정비
            label: 'LBL0003791',
            align: 'center',
            sortable: false,
            child: [
              {
                name: "realDate",
                field: "realDate",
                // 실행일
                label: "LBL0003792",
                style: 'width:80px',
                align: "center",
                sortable: false,
              },
              {
                name: "userName",
                field: "userName",
                // 관리자
                label: "LBLMANAGER",
                style: 'width:80px',
                align: "center",
                sortable: false,
              },
              {
                name: "resultName",
                field: "resultName",
                // 결과
                label: "LBL0003785",
                style: 'width:60px',
                type: 'custom',
                align: "center",
                sortable: false,
              },
            ]
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            // 관련법규
            label: "LBL0003610",
            style: 'width:200px',
            align: "left",
            sortable: false,
          },
        ],
        data: [],
      },
      flagItems: [
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '해당없음' },
      ],
      searchParam: {
        plantCd: null,
        // 설비점검 start
        safCheckTypeCd1: null,
        // 설비정비 start
        safCheckTypeCd2: null, 
        resultCd: null,
        startYmd: '',
        endYmd: '',
        noPlanResultEnrollFlag: null,
        relatedLawList: [],
        lawEquipmentFlag: null,
        equipmentType: null,
        year: '',
      },
      editable: true,
      period: [],
      type: '1',
      typeItems: [
        { code: '1', codeName: '점검' },
        { code: '2', codeName: '정비' },
      ],
      colorItems: [
        { stepperMstCd: 'Y', stepperMstNm: '점검', colorClass: 'green' },
        { stepperMstCd: 'N', stepperMstNm: '정비', colorClass: 'orange' },
      ],
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      listUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.listUrl = selectConfig.sop.min.equipment.maintain.list.url;
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      if (col && col.name === 'equipmentCdName') {
        this.popupOptions.param = {
          equipmentCd: row ? row.equipmentCd : '',
        }
        this.popupOptions.title = "LBL0003642"; // 설비마스터 상세
        this.popupOptions.target = () => import(`${"@/pages/mdm/fim/equipmentDetail.vue"}`);
      } else if(col && col.name === 'statusTypeCd') {
        this.popupOptions.title = this.$comm.getLangLabel('LBL0003793', {s1: row.equipmentCdName}); // row.equipmentCdName의 설비점검/정비 현황
        this.popupOptions.param = {
          statusTypeName: row.statusTypeName,
          minEquipmentCheckId: row.minEquipmentCheckId ? row.minEquipmentCheckId : '',
          equipmentCd: row.equipmentCd,
          realDate: row.realDate,
          year: this.searchParam.year,
        }
        this.popupOptions.target = () => import(`${"./equipmentRecord.vue"}`);
      } 
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    btnCancel() {
      this.isResult = false;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
  },
};
</script>
